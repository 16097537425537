import { SportsDic } from "models/sportsDic";
const redCard = "redcard";
const yellowCard = "yellowcard";

export const lacrosse: SportsDic = {
  playerHasUniformNumber: true,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 23,
  reserveMemberNum: 0,
  isCountDownTime: true,
  isPeriodFinish: true,
  isDisplaySeconds: false,
  isHiddenReserveMember: true,
  positionList: {
    ja: [
      {
        id: "G",
        name: "G"
      },
      {
        id: "DF",
        name: "DF"
      },
      {
        id: "MF",
        name: "MF"
      },
      {
        id: "FO",
        name: "FO"
      },
      {
        id: "LSM",
        name: "LSM"
      },
      {
        id: "AT",
        name: "AT"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "監督"
      },
      {
        id: "C",
        name: "コーチ"
      },
      {
        id: "S",
        name: "スタッフ"
      }
    ],
    en: [
      {
        id: "G",
        name: "G"
      },
      {
        id: "DF",
        name: "D"
      },
      {
        id: "MF",
        name: "M"
      },
      {
        id: "FO",
        name: "FOGO"
      },
      {
        id: "LSM",
        name: "LSM"
      },
      {
        id: "AT",
        name: "A"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "Head Coach"
      },
      {
        id: "C",
        name: "Coach"
      },
      {
        id: "S",
        name: "Support Staff"
      }
    ]
  },
  statTypes: [
    {
      key: "goal",
      name: "ゴール",
      name_en: "Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 1,
      gamePoint: 1,
      iconText: "G",
      isAssist: true
    },
    {
      key: "assist",
      name: "アシスト",
      name_en: "Assist",
      iconText: "A"
    },
    {
      key: "shot_miss",
      name: "シュート失敗",
      name_en: "Shot miss",
      name_short: "シュート失敗",
      name_short_en: "Shot miss",
      color: "gray",
      iconText: "SM"
    },
    {
      key: "save",
      name: "セーブ",
      name_en: "Save",
      name_short: "セーブ",
      name_short_en: "Save",
      color: "gray",
      iconText: "SV"
    },
    {
      key: "faceoff_win",
      name: "フェイスオフ勝利",
      name_en: "Face-off win",
      name_short: "フェイスオフ勝利",
      name_short_en: "Face-off win",
      color: "gray",
      iconText: "FO"
    },
    {
      key: "ground_ball",
      name: "グラウンドボール",
      name_en: "Ground ball",
      name_short: "グラウンドボール",
      name_short_en: "Ground ball",
      color: "gray",
      iconText: "GB"
    },
    {
      key: "clear_transition",
      name: "クリア/トランジション",
      name_en: "Clear/Transition",
      name_short: "クリア/トランジション",
      name_short_en: "Clear/Transition",
      color: "gray",
      iconText: "CT"
    },
    {
      key: "technical_foul",
      name: "テクニカルファウル",
      name_en: "Technical foul",
      name_short: "テクニカルファウル",
      name_short_en: "Technical foul",
      color: "gray",
      iconText: "TF"
    },
    {
      key: "personal_foul",
      name: "パーソナルファウル",
      name_en: "Personal foul",
      name_short: "パーソナルファウル",
      name_short_en: "Personal foul",
      color: "gray",
      iconText: "PF"
    },
    {
      key: "minor_penalty",
      name: "マイナーペナルティ",
      name_en: "Minor penalty",
      name_short: "マイナーペナルティ",
      name_short_en: "Minor penalty",
      color: "gray",
      iconText: "MIN"
    },
    {
      key: "major_penalty",
      name: "メジャーペナルティ",
      name_en: "Major penalty",
      name_short: "メジャーペナルティ",
      name_short_en: "Major penalty",
      color: "gray",
      iconText: "MAJ"
    },
    {
      key: yellowCard,
      name: "イエローカード",
      name_en: "Yellow card",
      name_short: "イエローカード",
      name_short_en: "Yellow card"
    },
    {
      key: "yellow_red_card",
      name: "イエロー/レッドカード",
      name_en: "Yellow/Red card",
      name_short: "イエロー/レッドカード",
      name_short_en: "Yellow/Red card",
      iconText: "Yellow/Red card"
    },
    {
      key: redCard,
      name: "レッドカード",
      name_en: "Red card",
      name_short: "レッドカード",
      name_short_en: "Red card"
    },
    {
      key: "timeout",
      name: "タイムアウト",
      name_en: "Timeout",
      name_short: "タイムアウト",
      name_short_en: "Timeout",
      iconText: "T",
      color: "gray",
      isNoPlayer: true,
      isUseEventTimer: true,
      isHiddenStat: true
    },
    {
      key: "officialTimeout",
      name: "オフィシャルタイムアウト",
      name_en: "Official timeOut",
      iconText: "OT",
      color: "gray",
      isNoPlayer: true,
      isUseEventTimer: true,
      isHiddenStat: true
    }
  ],
  periodNames: {},
  infringementNames: []
};
